<template>
  <div id="admin-index" class="admin-contents">
    <div class="admin-contents-title admin-index-title">个人中心</div>
    <div class="admin-index-info">
      <div class="admin-index-info-name">当前用户：{{ name }}</div>
      <div class="admin-index-info-last-seen">上次登录时间：{{ lastSeen }}</div>
    </div>
    <div class="admin-index-logout">
      <el-button type="danger" @click="logout">退出登录</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'admin-index',
  data () {
    return {
      name: '',
      lastSeen: ''
    }
  },
  methods: {
    ...mapMutations([
      'userLogout'
    ]),
    getAdminInfo () {
      this.$auth_http({
        url: this.$api.ADMIN_INFO_URL,
        method: 'GET'
      }).then(response => {
        this.name = response.name
        this.lastSeen = response.lastSeen
      })
    },
    logout () {
      this.userLogout()
      this.$router.push({ name: 'AdminLogin' })
    }
  },
  created () {
    this.getAdminInfo()
  }
}
</script>

<style lang="stylus" scoped>
#admin-index
  .admin-index-info
    width: 100%
    padding: 0 20px
    .admin-index-info-name
      margin: 10px 0
    .admin-index-info-last-seen
      margin: 10px 0
  .admin-index-logout
    padding: 0 20px
</style>
